import React, {useEffect, useState} from 'react'
import {
    useMutationRequestSelfBuildUserOtp,
    useMutationCreateSelfBuildDirectory,
    useMutationRequestStripeCustomerOtp,
    useMutationCreateStripeDirectory,
} from '~/apollo/queries/SelfBuild'
import {Token} from '../TokenContext'
import {useMutationSignInOtp} from '~/apollo/queries/SignInOtp'
import {SelfBuildFlow, Page, PageState} from './SelfBuildFlow'

export const SelfBuild = ({customerId}: {customerId?: string}) => {
    const [page, setPage] = useState<Page>(customerId ? 'check-your-mail' : 'sign-up')
    const [pageState, setPageState] = useState<PageState>({
        errorState: {},
    })

    const [, callRequestOtp] = useMutationRequestSelfBuildUserOtp()
    const [, callRequestStripeCustomerOtp] = useMutationRequestStripeCustomerOtp()
    const [, callSignInOtp] = useMutationSignInOtp()
    const [, callCreateSelfBuildDirectory] = useMutationCreateSelfBuildDirectory()
    const [, callCreateStripeDirectory] = useMutationCreateStripeDirectory()
    const {setToken} = Token.useContainer()

    useEffect(() => {
        if (customerId) {
            callRequestStripeCustomerOtp({customerId}).then((result) => {
                setPageState({email: result.data?.requestStripeCustomerOTP || undefined, errorState: {}})
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId])

    return (
        <SelfBuildFlow
            page={page}
            setPage={setPage}
            pageState={pageState}
            setPageState={setPageState}
            requestOtp={async (email: string) => {
                await callRequestOtp({email})
            }}
            signInOtp={async (otp: string, email: string) => {
                const result = await callSignInOtp({otp, email})
                result && setToken(result)
                return !!result
            }}
            onComplete={async (directoryName: string) => {
                if (customerId) {
                    const result = await callCreateStripeDirectory({
                        organisationName: directoryName,
                        customerId,
                        organisationToClone: 'Self Build Template',
                    })
                    return result.data?.createStripeDirectory
                } else {
                    const result = await callCreateSelfBuildDirectory({
                        organisationName: directoryName,
                        organisationToClone: 'Self Build Template',
                    })
                    return result.data?.createSelfBuildDirectory
                }
            }}
        />
    )
}
